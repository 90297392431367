import "../trix-editor-overrides";
import '@fortawesome/fontawesome-free/js/all';

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");

// jQuery をグローバルに設定
import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete'; // jQuery UI のインポート
global.$ = global.jQuery = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix")
// require("@rails/actiontext")

// フラッシュメッセージ (4秒後に消える設定)
// $(function () {
//   $('.flash').fadeOut(4000);
// });

$(document).ready(function () {
  console.log('Flash element:', $('.flash').length); // .flashが存在するか確認
  $('.flash').fadeOut(4000, function () {
    console.log('Flash has faded out.'); // アニメーション終了確認
  });
});